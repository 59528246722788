import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { GranTypeEnum } from '@interfaces/token/gran.type.enum';
import { TokenResponseInterface } from '@interfaces/token/token.response.interface';
import { from, Observable, Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  public auth$: Subscription;
  private tokens: TokenResponseInterface;
  private clientID = environment.client.name;
  private clientSecret = environment.client.secret;
  private clientCredentials: string;

  constructor(private authService: AuthService) {
    this.clientCredentials = btoa(`${this.clientID}:${this.clientSecret}`);
    this.tokens = null as TokenResponseInterface;
    this.auth$ = this.authService
      .getObservable('tokens')
      .pipe(filter((tokens) => !!tokens))
      .subscribe((value) => {
        this.tokens = value.user ? value : null;
      });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (this.tokens) {
      const unixDate = Math.floor(Date.now() / 1000);
      if (this.tokens.expires_in <= unixDate) {
        if (request.body?.grant_type === GranTypeEnum.refresh_token) {
          return next.handle(request);
        }

        const newToken = this.authService.refresh();
        return from(newToken).pipe(
          switchMap((token) => {
            this.tokens = token;
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${this.tokens.access_token}`,
              },
            });
            return next.handle(request);
          }),
        );
      }

      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.tokens.access_token}`,
        },
      });
    } else if (this.clientCredentials) {
      request = request.clone({
        setHeaders: {
          Authorization: `Basic ${this.clientCredentials}`,
        },
      });
    }

    return next.handle(request);
  }
}
