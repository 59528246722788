import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ExtendedRecordset } from '@interfaces/global/extendedRecordset.interface';
import { GranTypeEnum } from '@interfaces/token/gran.type.enum';
import {
  CredentialsInterface,
  TokenResponseInterface,
} from '@interfaces/token/token.response.interface';
import { UserResponseInterface } from '@interfaces/user/user.response.interface';
import { IonicToasterService } from '@ionServices/toaster/ionic-toaster.service';
import { TranslateService } from '@ngx-translate/core';
import {
  ErrorServiceInterface,
  ErrorsService,
} from '@services/errors/errors.service';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { SessionStorageService } from '@services/session-storage/session-storage.service';
import { BehaviorSubject, lastValueFrom, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BasicObservableService } from '../basic-observable/basic-observable.service';

@Injectable()
export abstract class AbstractAuthService {
  public currentUser: UserResponseInterface;
  public currentToken: string;
  public currentRefreshToken: string;
  public headers: string;
  public rateCall: any;
  public logRateCall: any;

  protected dataStore: {
    tokens: TokenResponseInterface;
    user: UserResponseInterface;
    headers: HttpHeaders;
  };
  protected observables: {
    tokens: BehaviorSubject<TokenResponseInterface>;
    user: BehaviorSubject<UserResponseInterface>;
    headers: BehaviorSubject<HttpHeaders>;
  };
  protected behaviorSubjects: {
    tokens: Observable<TokenResponseInterface>;
    user: Observable<UserResponseInterface>;
    headers: Observable<HttpHeaders>;
  };

  protected baseUrl: string;
  protected errorsSection: string;
  protected clientID = environment.client.name;
  protected clientSecret = environment.client.secret;

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService,
    protected storage: LocalStorageService,
    protected session: SessionStorageService,
    protected basicObservable: BasicObservableService,
    protected toasterIonic: IonicToasterService,
    protected translate: TranslateService,
  ) {
    this.errorsSection = 'errors.auth';
    this.headers = 'Headers';
    this.baseUrl = `${environment.api}/tokens`;

    this.dataStore = {
      tokens: {} as TokenResponseInterface,
      user: {} as UserResponseInterface,
      headers: {} as HttpHeaders,
    };
    this.observables = {
      tokens: new BehaviorSubject(
        null,
      ) as BehaviorSubject<TokenResponseInterface>,
      user: new BehaviorSubject(null) as BehaviorSubject<UserResponseInterface>,
      headers: new BehaviorSubject(null) as BehaviorSubject<HttpHeaders>,
    };
    this.behaviorSubjects = {
      tokens: this.observables.tokens.asObservable(),
      user: this.observables.user.asObservable(),
      headers: this.observables.headers.asObservable(),
    };
    this.basicObservable
      .getObservable('actual-config')
      .pipe(
        filter((pages) => !!pages),
        map((page) => page[page.length - 1]),
      )
      .subscribe((page) => {
        this.baseUrl = `${page?.payload?.api || environment.api}/tokens`;
      });
  }

  getObservable(section = 'user'): Observable<any> {
    return this.behaviorSubjects[section];
  }

  // login
  public login(credentials: CredentialsInterface, isLocalStorage = false) {
    const clientCredentials = btoa(`${this.clientID}:${this.clientSecret}`);

    const headers = new HttpHeaders().set(
      'Authorization',
      `Basic ${clientCredentials}`,
    );

    this.http
      .post(this.baseUrl, credentials, { observe: 'response', headers })
      .subscribe({
        next: (resp) => {
          this.loginSuccess(resp.body, resp.headers, isLocalStorage);
        },
        error: (error) => {
          this.loginError(error);
        },
      });
  }

  protected loginSuccess(
    body,
    headers: HttpHeaders = {} as HttpHeaders,
    isLocalStorage = false,
  ) {
    body = body.data;
    body.expires_in = this.parseExpiresIn(
      body.access_token,
      body.expires_in / 2,
    );
    this.setDataStore(body as TokenResponseInterface, headers, isLocalStorage);
  }

  protected loginError(error) {
    this.logout();
    const section =
      error.status === 401 || error.message === 'Authentication Canceled'
        ? this.errorsSection
        : 'standard';
    this.errorsService.create(section, {
      payload: error,
    } as ErrorServiceInterface);
  }

  public logout() {
    this.setDataStore();
  }

  // refresh
  async refresh(
    isLocalStorage: boolean = false,
    token?: string,
  ): Promise<TokenResponseInterface> {
    const refreshToken = token ? token : this.dataStore.tokens.refresh_token;
    const clientCredentials = btoa(`${this.clientID}:${this.clientSecret}`);

    const credentials = {
      grant_type: GranTypeEnum.refresh_token,
      refresh_token: refreshToken,
    } as CredentialsInterface;

    const headers = new HttpHeaders().set(
      'Authorization',
      `Basic ${clientCredentials}`,
    );

    const httpResponse: HttpResponse<any> = await lastValueFrom(
      this.http.post(this.baseUrl, credentials, {
        observe: 'response',
        headers,
      }),
    );
    if (httpResponse.ok) {
      const body = httpResponse.body.data;
      body.expires_in = this.parseExpiresIn(
        body.access_token,
        body.expires_in / 2,
      );

      this.setDataStore(
        body as TokenResponseInterface,
        httpResponse.headers,
        isLocalStorage,
      );

      return body;
    } else {
      clearTimeout(this.rateCall);
      clearTimeout(this.logRateCall);
      this.logout();
      if (httpResponse.status === 401) {
        const section = this.errorsSection;
        this.errorsService.create(section, {
          payload: httpResponse,
        } as ErrorServiceInterface);
      }
      if (httpResponse.status === 430) {
        this.toasterIonic.presentToastWithOptions(
          this.translate.instant('errors.sessionExpired'),
          'error-toastr',
          { duration: 15000 },
        );
        const section = this.errorsSection;
        this.errorsService.create(section, {
          payload: httpResponse,
        } as ErrorServiceInterface);
      }
    }
    /* 
    this.http
      .post(this.baseUrl, credentials, { observe: 'response', headers })
      .subscribe({
        next: (resp: HttpResponse<ExtendedRecordset<any>>) => {
          const body = resp.body.data;
          body.expires_in = this.parseExpiresIn(
            body.access_token,
            body.expires_in / 2,
          );

          this.setDataStore(
            body as TokenResponseInterface,
            resp.headers,
            isLocalStorage,
          );
          //resolve

        },
        error: (error) => {
          clearTimeout(this.rateCall);
          clearTimeout(this.logRateCall);
          this.logout();
          if (error.status === 401) {
            const section = this.errorsSection;
            this.errorsService.create(section, {
              payload: error,
            } as ErrorServiceInterface);
          }
          if (error.status === 430) {
            this.toasterIonic.presentToastWithOptions(
              this.translate.instant('errors.sessionExpired'),
              'error-toastr',
              { duration: 15000 },
            );
            const section = this.errorsSection;
            this.errorsService.create(section, {
              payload: error,
            } as ErrorServiceInterface);
          }
          //reject
        },
      }); */
  }

  // loadTokenFromStorageOrSession
  loadTokens() {
    let storageSub: Subscription;
    let sessionStorageSub: Subscription;

    storageSub = this.storage
      .getObservable('loadToken')
      .pipe(
        filter((store: any) => !!store),
        map((store: any) => store.tokens),
      )
      .subscribe((tokens) => {
        if (storageSub) {
          storageSub.unsubscribe();
        }

        if (!tokens) {
          this.session.load('tokens', 'loadToken');
        } else {
          this.setToken(tokens, true);
        }
      });

    sessionStorageSub = this.session
      .getObservable('loadToken')
      .pipe(
        filter((sessionStore) => typeof sessionStore.tokens !== 'undefined'),
        map((sessionStore) => sessionStore.tokens),
      )
      .subscribe((tokens) => {
        if (sessionStorageSub) {
          sessionStorageSub.unsubscribe();
        }

        if (tokens) {
          this.setToken(tokens, false);
        }
      });

    this.storage.load('tokens', 'loadToken');
  }

  protected setToken(tokens, isLocalStorage) {
    this.setDataStore(tokens, {} as HttpHeaders, isLocalStorage);
    const unixDate = Math.floor(Date.now() / 1000);

    if (tokens.expires_in <= unixDate) {
      this.refresh();
    }
  }

  protected setDataStore(
    tokens: TokenResponseInterface = {} as TokenResponseInterface,
    headers: HttpHeaders = {} as HttpHeaders,
    isLocalStorage: boolean = false,
  ) {
    if (tokens.access_token && tokens.user) {
      if (isLocalStorage) {
        this.storage.create({ key: 'tokens', value: tokens });
        this.storage.create({ key: 'user', value: tokens.user });
      } else {
        this.session.create({ key: 'tokens', value: tokens });
        this.session.create({ key: 'user', value: tokens.user });
      }
    } else {
      this.storage.remove('tokens');
      this.storage.remove('user');
      this.session.remove('tokens');
      this.session.remove('user');
    }

    this.dataStore.tokens = tokens;
    this.observables.tokens.next(Object.assign({}, this.dataStore).tokens);
    this.currentToken = tokens?.access_token;
    this.currentRefreshToken = tokens?.refresh_token;

    if (tokens?.refresh_token) {
      const expiresIn_refreshToken = this.parseExpiresIn(tokens?.refresh_token);
      const checkoutBeforeAlert = Number(environment.sessionCheckout) * 60; // Number(environment.sessionCheckout) * 60
      const maxSessionDuration = Number(environment.sessionDuration) * 60;
      const sessionTimeRest = expiresIn_refreshToken - checkoutBeforeAlert;

      if (expiresIn_refreshToken >= maxSessionDuration) {
        this.rateCall = setTimeout(() => {
          this.alertTimeOut();
        }, maxSessionDuration * 1000);
      }
      if (
        expiresIn_refreshToken < maxSessionDuration &&
        expiresIn_refreshToken > checkoutBeforeAlert
      ) {
        this.rateCall = setTimeout(() => {
          this.alertTimeOut();
        }, sessionTimeRest * 1000);
      }

      if (expiresIn_refreshToken <= checkoutBeforeAlert) {
        this.alertTimeOut();
      }

      this.logRateCall = setTimeout(() => {
        this.logout();
        clearTimeout(this.logRateCall);
        window.location.reload();
      }, expiresIn_refreshToken * 1000);
    }

    let user: UserResponseInterface;
    user = tokens.user ? tokens.user : undefined;
    this.dataStore.user = user;
    this.currentUser = user;

    this.observables.user.next(Object.assign({}, this.dataStore).user);
    this.dataStore.headers = headers;
    this.observables.headers.next(Object.assign({}, this.dataStore).headers);
  }

  protected parseExpiresIn(accessToken, reduceTime = 0) {
    let tokens = accessToken?.split('.');
    tokens = JSON.parse(atob(tokens[1]));
    return tokens?.exp - reduceTime;
  }
  public alertTimeOut() {
    this.toasterIonic.presentToastWithOptions(
      this.translate.instant('errors.sessionExpiresSoon'),
      'error-toastr',
      { duration: 15000 },
    );
  }

  public abstract loginLinkedIn(): Promise<void>;

  public abstract loginFacebook(): Promise<void>;

  public abstract loginGoogle(): Promise<void>;
}
