import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { UserResponseInterface } from '@interfaces/user/user.response.interface';
import { WalletResponseInterface } from '@interfaces/user/wallet/wallet.response.interface';
import { NavController } from '@ionic/angular';
import { AuthService } from '@services/auth/auth.service';
import { WalletsService } from '@services/users/wallets/wallets.service';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BasicObservableService } from '../shared/services/basic-observable/basic-observable.service';

@Component({
  selector: 'app-sso',
  templateUrl: 'sso.component.html',
})
export class SsoComponent implements OnInit, OnDestroy {
  private subQuery: Subscription;
  private subAuth: Subscription;
  private subWallets: Subscription;
  private subConfig: Subscription;
  private goto: 'BTC' | 'ETH' | 'HOME' = 'HOME';
  private user: UserResponseInterface;
  private readonly listSection = 'standard';
  pBarValue: number;
  constructor(
    private readonly authService: AuthService,
    private readonly walletsService: WalletsService,
    private readonly route: ActivatedRoute,
    private readonly navCtrl: NavController,
    private readonly basicObservable: BasicObservableService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.runDeterminateProgress();

    this.subAuth = this.authService
      .getObservable()
      .pipe(filter((user: UserResponseInterface) => !!user))
      .subscribe((user: UserResponseInterface) => this.onAuth(user));

    this.subWallets = this.walletsService
      .getObservable(this.listSection)
      .pipe(filter((wallets: WalletResponseInterface[]) => !!wallets))
      .subscribe((wallets) => this.onWallets(wallets));
    this.subConfig = this.basicObservable
      .getObservable('actual-config')
      .pipe(
        filter((pages) => !!pages),
        map((page) => page[page.length - 1]),
      )
      .subscribe(() => {
        this.onConfig();
      });
  }

  private onConfig() {
    this.subQuery = this.route.queryParams.subscribe((params: Params) =>
      this.onQuery(params),
    );
  }

  private onAuth(user: UserResponseInterface) {
    if (this.user?.id === user?.id) return;
    this.user = user;
    if (this.user) {
      const urlParams = [this.user.id];
      this.walletsService.getAll({ limit: 0 }, this.listSection, urlParams);
    }
  }

  private onWallets(wallets: WalletResponseInterface[]) {
    let foundSymbol = false;
    // tslint:disable-next-line: forin
    if (this.goto && this.goto !== 'HOME') {
      for (const key in wallets) {
        const wallet = wallets[key];
        if (wallet.asset?.symbol === this.goto) {
          foundSymbol = true;
          this.navigateTo(`/admin/wallets/${wallet.id}/buy`);
          break;
        }
      }
    }

    if (!foundSymbol) {
      this.navigateTo('/admin');
    }
  }

  private runDeterminateProgress() {
    for (let index = 0; index <= 100; index++) {
      this.setPercentBar(+index);
    }
  }
  private setPercentBar(i) {
    setTimeout(() => {
      const apc = i / 100;
      this.pBarValue = apc;
    }, 30 * i);
  }

  private onQuery(params: Params) {
    if (params.goto) {
      this.goto = params.goto;
    }
    if (params.sso) {
      this.authService.refresh(false, params.sso);
    }
  }

  navigateTo(goto) {
    this.navCtrl.navigateRoot(goto);
  }

  ngOnDestroy(): void {
    this.subQuery.unsubscribe();
    this.subWallets.unsubscribe();
    this.subAuth.unsubscribe();
    this.subConfig.unsubscribe();
  }
}
