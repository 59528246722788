import { countries } from './../shared/interfaces/countries';
import { ToastOptions } from '@ionic/core';
import { BrowserOptions } from '@sentry/browser';
import { LaddaConfigArgs } from 'angular2-ladda';
import { IPartialConfigOptions } from 'ng2-ui-auth/lib/config-interfaces';

export const environment = {
  environment: 'local',
  production: false,
  static: '',
  api: 'https://api.onyzeiframe.kubide.rocks/v1',
  client: {
    name: '59q2xdPPw',
    secret: 'onyzeRulez1!',
  },

  sentry: {
    dsn: '',
    debug: false,
    enabled: false,
  } as BrowserOptions,
  ladda: {
    style: 'zoom-in',
    spinnerSize: 30,
    spinnerColor: 'grey',
    spinnerLines: 12,
  } as LaddaConfigArgs,
  toaster: {
    showCloseButton: false,
    position: 'bottom',
    closeButtonText: 'Done',
    duration: 3000,
  } as ToastOptions,
  toastOptions: {
    mode: 'md',
    duration: 3000,
  } as ToastOptions,
  storageDB: {
    name: 'onyze-wallet',
  },
  socialAuth: {} as IPartialConfigOptions,

  withdrawal: {
    fees: 0.001, // 0.1%
    min: 2,
  },

  buy: {
    fees: 0.0149, // 0.0149 1.49%
    fixCommission: 0,
    maxOperation: 250,
    min: 50,
    max: 2000,
  },

  sell: {
    fees: 0.0149, // 0.0149 1.49%
    min: 50,
    max: 2000,
  },

  testnet: true,

  networks: {
    BTC: {
      address: 'http://chain.so/address/BTCTEST',
      transaction: 'https://live.blockcypher.com/btc-testnet/tx/',
      color: '#F5B300',
    },
    ETH: {
      address: 'https://ropsten.etherscan.io/address/',
      transaction: 'https://ropsten.etherscan.io/tx/',
      color: '#8A92B2',
    },
    XRP: {
      address: '',
      transaction: '',
      color: '#01A7DF',
    },
    LIBRA: {
      address: '',
      transaction: '',
      color: '#F5B300',
    },
  },
  assets: {
    BTC: {
      buy: '5',
      sell: '5',
      withdrawal: '8',
      balance: '8',
    },
    ETH: {
      buy: '8',
      sell: '8',
      withdrawal: '8',
      balance: '8',
    },
    ADA: {
      buy: '1',
      sell: '1',
      withdrawal: '6',
      balance: '6',
    },
    USDT: {
      buy: '2',
      sell: '2',
      withdrawal: '6',
      balance: '6',
    },
    XRP: {
      buy: '0',
      sell: '0',
      withdrawal: '6',
      balance: '6',
    },
    SOL: {
      buy: '2',
      sell: '2',
      withdrawal: '8',
      balance: '8',
    },
    DOT: {
      buy: '2',
      sell: '2',
      withdrawal: '8',
      balance: '8',
    },
    DOGE: {
      buy: '0',
      sell: '0',
      withdrawal: '8',
      balance: '8',
    },
    UNI: {
      buy: '2',
      sell: '2',
      withdrawal: '8',
      balance: '8',
    },
    LINK: {
      buy: '2',
      sell: '2',
      withdrawal: '8',
      balance: '8',
    },
    LTC: {
      buy: '3',
      sell: '3',
      withdrawal: '8',
      balance: '8',
    },
    BCH: {
      buy: '3',
      sell: '3',
      withdrawal: '8',
      balance: '8',
    },
    MATIC: {
      buy: '1',
      sell: '1',
      withdrawal: '8',
      balance: '8',
    },
  },
  viewConfiguration: {
    closeButton: {
      enabled: true,
      url: 'http://url.com',
    },
    totalBalance: true,
    wallets: true,
    assets: true,
    assetDetails: true,
    buy: true,
    sell: true,
    alerts: true,
    scheduled: true,
    withdrawal: true,
    address: true,
    chart1: true,
    chart1Title: false,
    chart2: true,
    chart2Title: false,
    investment: true,
    stats: true,
    operations: true,
    home: {
      chart1: true,
      chart2: true,
      wallets: {
        eurVar: true,
        percentVar: true,
      },
      assets: {
        eurVar: true,
        percentVar: true,
      },
      buttons: {
        firstIcon: 'faChartArea',
        secondIcon: 'faChartPie',
      },
    },
    operationsDetail: {
      change: true,
      amount: true,
      transactionNumber: true,
      status: true,
      quantity: true,
      wallet: true,
      fee: true,
    },
    assetDetail: {
      rate: true,
      circulatingSupply: true,
      maxSupply: true,
      totalSupply: true,
      percentChange1h: true,
      percentChange24h: true,
      percentChange7d: true,
      volume24h: true,
      marketCap: true,
      marketCapRank: true,
      fullyDilutedValuation: true,
      high24h: true,
      low24h: true,
      ath: true,
      athPercentChange: true,
      athDate: true,
      atl: true,
      atlPercentChange: true,
      atlDate: true,
      percentChange14d: true,
      percentChange200d: true,
      percentChange1y: true,
    },
    dashboard: {
      secondText: true,
      extraText: true,
      buttons: {
        firstIcon: 'faChartLine',
        secondIcon: 'faWallet',
      },
      shortcuts: {
        show: true,
        buttons: {
          buy: {
            show: true,
            icon: 'faCartArrowDown',
          },
          sell: {
            show: true,
            icon: 'faHandHoldingUsd',
          },
          send: {
            show: false,
            icon: 'faArrowUp',
          },
          receive: {
            show: false,
            icon: 'faArrowDown',
          },
        },
      },
    },
    assetsOperations: {
      buy: [
        'BTC',
        'ETH',
        'USDT',
        'XRP',
        'DOGE',
        'ADA',
        'MATIC',
        'DOT',
        'SOL',
        'UNI',
        'LTC',
        'LINK',
        'BCH',
      ],
      sell: [
        'BTC',
        'ETH',
        'USDT',
        'XRP',
        'DOGE',
        'ADA',
        'MATIC',
        'DOT',
        'SOL',
        'UNI',
        'LTC',
        'LINK',
        'BCH',
      ],
      withdrawal: ['BTC', 'ETH'],
      address: ['BTC', 'ETH'],
    },
    doubleConfirmation: false,
    feeBuy: true,
    feeSell: true,
  },
  countries,
  blacklist: [],
  homeDecimals: {
    assetDefault: '1.2-2',
    assetSmall: '1.4-4',
    variationAssetDefault: '1.3-3',
    variationAssetSmall: '1.5-5',
    walletDefault: '1.2-2',
  },
  sessionCheckout: '5',
  sessionDuration: '55',
};

environment.socialAuth = {
  baseUrl: environment.api,
  providers: {
    linkedin: {
      url: 'tokens',
      clientId: '86az306lssh0h3',
    },
    facebook: {
      url: 'tokens',
      clientId: '1999260230336024',
    },
    google: {
      url: 'tokens',
      clientId: '392164456106-3jtkjfjc6hv92o1ccjqd053ktgu5ksls',
    },
  },
};
