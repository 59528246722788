import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthHttpInterceptor } from '../auth/auth.http.interceptor';
import { BasicObservableModule } from '../basic-observable/basic-observable.module';
// services
import { ConfigsService } from './config.service';

@NgModule({
  imports: [HttpClientModule, BasicObservableModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
})
export class ConfigsServiceModule {
  static forRoot(): ModuleWithProviders<ConfigsServiceModule> {
    return {
      ngModule: ConfigsServiceModule,
      providers: [ConfigsService],
    };
  }
}
