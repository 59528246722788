export const countries = {
  AND: { iso: 'AND', name: 'Andorra' },
  AUT: { iso: 'AUT', name: 'Austria' },
  BEL: { iso: 'BEL', name: 'Belgium' },
  BGR: { iso: 'BGR', name: 'Bulgaria' },
  HRV: { iso: 'HRV', name: 'Croatia' },
  CYP: { iso: 'CYP', name: 'Cyprus' },
  CZE: { iso: 'CZE', name: 'Czech Republic' },
  DNK: { iso: 'DNK', name: 'Denmark' },
  EST: { iso: 'EST', name: 'Estonia' },
  FIN: { iso: 'FIN', name: 'Finland' },
  FRA: { iso: 'FRA', name: 'France' },
  // DEU: { iso: 'DEU', name: 'Germany' },
  GRC: { iso: 'GRC', name: 'Greece' },
  HUN: { iso: 'HUN', name: 'Hungary' },
  IRL: { iso: 'IRL', name: 'Ireland' },
  ITA: { iso: 'ITA', name: 'Italy' },
  LVA: { iso: 'LVA', name: 'Latvia' },
  LTU: { iso: 'LTU', name: 'Lithuania' },
  LUX: { iso: 'LUX', name: 'Luxembourg' },
  MLT: { iso: 'MLT', name: 'Malta' },
  NLD: { iso: 'NLD', name: 'Netherlands' },
  POL: { iso: 'POL', name: 'Poland' },
  PRT: { iso: 'PRT', name: 'Portugal' },
  ROU: { iso: 'ROU', name: 'Romania' },
  SVK: { iso: 'SVK', name: 'Slovakia' },
  SVN: { iso: 'SVN', name: 'Slovenia' },
  ESP: { iso: 'ESP', name: 'Spain' },
  SWE: { iso: 'SWE', name: 'Sweden' },
  GBR: { iso: 'GBR', name: 'United Kingdom' },
  ISL: { iso: 'ISL', name: 'Iceland' },
  LIE: { iso: 'LIE', name: 'liechtenstein' },
  NOR: { iso: 'NOR', name: 'Norway' },
  CHE: { iso: 'CHE', name: 'Switzerland' },
};
