import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { BasicObservableService } from '../basic-observable/basic-observable.service';

@Injectable()
export class ConfigsService {
  constructor(protected basicObservable: BasicObservableService) {}

  public getJSONConfig() {
    const basicOb = {
      hash: 'actual-config',
      payload: environment,
    };
    this.basicObservable.create(basicOb, basicOb.hash);
  }
}
